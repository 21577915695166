import React from "react"
import styled from "styled-components"

import { BodyCopy } from "../common"

const StyledTwoColText = styled.section`
  padding: 0 ${props => props.theme.padding.hMobile};
  max-width: ${props => props.theme.containerWidths.standard};
  margin: auto;
  > h2 {
    margin-bottom: 2rem;
  }
  @media ${props => props.theme.breakpoints.medium} {
    padding: 0 ${props => props.theme.padding.hDesk};
    > ${BodyCopy} {
      columns: 2;
      column-gap: 60px;
    }
  }
`

export const TwoColText = ({ text, heading, scrollID }) => {
  return (
    <StyledTwoColText id={scrollID}>
      {heading.text && <h2>{heading.text}</h2>}
      <BodyCopy dangerouslySetInnerHTML={{ __html: text.html }} />
    </StyledTwoColText>
  )
}
