import React from "react"
import styled from "styled-components"
import AnchorLink from "react-anchor-link-smooth-scroll"

import { BodyCopy, FlexBox } from "../common"
import DownIcon from "../../svg/arrow-down.svg"

const StyledPageIntro = styled.section`
  padding: 0 ${props => props.theme.padding.hMobile};
  > nav {
    margin-top: 2rem;
    ul {
      list-style: none;
      padding-left: 0px;
    }
    li {
      padding: 0.2rem 0;
      text-decoration: underline;
      > a {
        color: inherit;
      }
    }
  }
  @media ${props => props.theme.breakpoints.medium} {
    padding: 0 ${props => props.theme.padding.hDesk};
    max-width: ${props => props.theme.containerWidths.standard};
    margin: auto;
    display: grid;
    grid-template-columns: 1fr 1fr 1fr 1fr 1fr 1fr 1fr 1fr 1fr 1fr 1fr 1fr;
    > ${BodyCopy} {
      grid-column: 1 / span 8;
    }
    > nav {
      margin-top: 1rem;
      grid-column: 10 / span 2;
    }
  }
`

const JumpToHeading = styled(FlexBox)`
  color: ${props => props.theme.colors.pullColor};
  margin-bottom: 1rem;
  > svg {
    width: 0.7rem;
    fill: ${props => props.theme.colors.pullColor};
    margin-left: 1rem;
  }
`

export const PageIntro = ({ text, items }) => {
  return (
    <StyledPageIntro>
      <BodyCopy dangerouslySetInnerHTML={{ __html: text.html }} />
      <nav>
        <JumpToHeading justifyContent="flex-start">
          Jump to Links <DownIcon />
        </JumpToHeading>
        <ul>
          {items.map(item => (
            <li>
              <AnchorLink href={`#${item.id}`}>
                {item.primary.nav_title}
              </AnchorLink>
            </li>
          ))}
        </ul>
      </nav>
    </StyledPageIntro>
  )
}
