import React, { useState, useContext } from "react"

import { SiteContext } from "../../context"

import {
  BodyCopy,
  PrismicImage,
  StyledButton,
  StyledImageText,
  PopupForm,
} from "../common"

export const RegionBlock = ({
  image,
  text,
  form_text,
  reverse_layout,
  scrollID,
  region,
}) => {
  const reverse = reverse_layout === "true"
  const [isOpen, setOpen] = useState(false)
  const { toggleModalOpen } = useContext(SiteContext)
  return (
    <>
      <StyledImageText id={scrollID} reverse={reverse}>
        <PrismicImage {...image} />
        <div>
          <BodyCopy dangerouslySetInnerHTML={{ __html: text.html }} />
          <StyledButton
            onClick={() => {
              toggleModalOpen()
              setOpen(true)
            }}
          >
            Enquire Now
          </StyledButton>
        </div>
      </StyledImageText>
      <PopupForm
        form_name={region}
        text={form_text}
        isOpen={isOpen}
        onClose={() => {
          toggleModalOpen()
          setOpen(false)
        }}
      />
    </>
  )
}
