import React from "react"
import styled from "styled-components"

import useFormValidation from "../../hooks/useFormValidation"

import {
  StyledButton,
  StyledInput,
  StyledTextArea,
  StyledSelect,
  FlexBox,
  BodyCopy,
} from "../common"

import Chevron from "../../svg/chevron.svg"

const StyledForm = styled.form`
  padding: 0 ${props => props.theme.padding.hMobile};
  max-width: ${props => props.theme.containerWidths.standard};
  margin: auto;

  ${StyledTextArea}, ${StyledInput}, ${StyledSelect} {
    width: 100%;
  }

  ${StyledButton} {
    display: flex; 
    margin-left:auto;
    margin-top: 1rem;
  }

  @media ${props => props.theme.breakpoints.medium} {
    padding: 0 ${props => props.theme.padding.hDesk};
   ${StyledInput}, ${StyledSelect} {
      width: calc(50% - 1rem);
    }
  }
`

const INITIAL_STATE = {}

export const ContactForm = () => {
  const { handleSubmit, handleChange, success, values } = useFormValidation(
    INITIAL_STATE
  )

  return (
    <StyledForm
      name="contact-form"
      data-netlify="true"
      data-netlify-honeypot="bot-field"
      subject={`Directus Enquiry ${values.region} ${values.service}`}
      onSubmit={handleSubmit}
    >
      <input type="hidden" name="form-name" value="contact-form" />
      <p hidden>
        <label>
          <input name="bot-field" onChange={handleChange} />
        </label>
      </p>
      {success ? (
        <BodyCopy center>
          <h3>Thank You</h3>
          <p>One of our team members will contact you shortly.</p>
        </BodyCopy>
      ) : (
        <>
          <FlexBox>
            <StyledInput
              type="text"
              name="firstName"
              placeholder="First Name"
              onChange={handleChange}
            />
            <StyledInput
              type="text"
              name="lastName"
              placeholder="Last Name"
              onChange={handleChange}
            />
            <StyledInput
              type="email"
              name="email"
              placeholder="Email Address"
              onChange={handleChange}
            />
            <StyledInput
              type="text"
              name="company"
              placeholder="Company Name"
              onChange={handleChange}
            />
            <StyledSelect>
              <Chevron />
              <select name="region" onChange={handleChange}>
                <option disabled selected value>
                  -- Region --
                </option>
                <option value="New Zealand">New Zealand</option>
                <option value="USA">USA</option>
                <option value="Asia">Asia</option>
              </select>
            </StyledSelect>
            <StyledSelect>
              <Chevron />
              <select name="service" onChange={handleChange}>
                <option disabled selected value>
                  -- Service --
                </option>
                <option value="Ingredients">Ingredients</option>
                <option value="Retail">Retail</option>
                <option value="Machinery">Machinery</option>
              </select>
            </StyledSelect>
          </FlexBox>
          <StyledTextArea
            placeholder="Your message"
            rows={5}
            name="message"
            onChange={handleChange}
          />
          <StyledButton long>Submit</StyledButton>
        </>
      )}
    </StyledForm>
  )
}
