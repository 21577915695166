import React from "react"

import { BodyCopy, PrismicImage, ButtonLink, StyledImageText } from "../common"
import ArrowRight from "../../svg/arrow-right.svg"

export const ImageText = ({
  image,
  text,
  link,
  link_text,
  reverse_layout,
  scrollID,
}) => {
  const reverse = reverse_layout === "true"
  return (
    <StyledImageText id={scrollID} reverse={reverse}>
      <PrismicImage {...image} />
      <div>
        <BodyCopy dangerouslySetInnerHTML={{ __html: text.html }} />
        {link.url && (
          <ButtonLink to={link.url}>
            {link_text} <ArrowRight />
          </ButtonLink>
        )}
      </div>
    </StyledImageText>
  )
}
