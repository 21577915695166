import React from "react"
import styled from "styled-components"
import { PrismicImage, CardCarousel, BodyCopy, ButtonLink } from "../common"

import EllipsisText from "react-ellipsis-text"

const StyledCarouselCard = styled.div`
  flex: 0 0 auto;
  width: 100%;
  user-select: none;
  padding: 0 ${props => props.theme.padding.hMobile};
  box-sizing: content-box;
  position: relative;
  * {
    -webkit-user-select: none;
    -khtml-user-select: none;
    -moz-user-select: none;
    -o-user-select: none;
    user-select: none;
  }
  ${ButtonLink} {
    margin-top: 1rem;
    display: inline-block;
  }
  ${BodyCopy} {
    color: #fff;
    width: calc(100% - 3rem);
    height: 100%;
    position: absolute;
    top: 0;
    left: 1.5rem;
    background: rgba(0, 0, 0, 0.5);
    padding: 1.5rem;
    display: flex;
    flex-direction: column;
    justify-content: flex-end;
    align-items: flex-start;
  }
  &:focus {
    outline: none;
  }
  @media ${props => props.theme.breakpoints.medium} {
    width: 450px;
    padding: 0 3rem 0 0;
    overflow: hidden;
    transform: scale(0.95);
    transition: all 0.5s;
    &:last-child {
      padding: 0;
      ${BodyCopy} {
        width: 100%;
      }
    }

    * {
      transition: all 0.5s;
    }

    > ${BodyCopy} {
      padding: 2.8rem;
      left: 0;
      > span {
        transform: translate3d(0, 80%, 0);
        > span {
          display: block;
        }
        span,
        ${ButtonLink} {
          opacity: 0;
        }
      }
    }
    &:hover {
      transform: scale(1);
      > ${BodyCopy} {
        > span {
          transform: translate3d(0, 0, 0);
          span,
          ${ButtonLink} {
            opacity: 1;
          }
        }
      }
    }
  }
`

const CarouselCard = ({ image, heading, text, page }) => {
  return (
    <StyledCarouselCard>
      <PrismicImage {...image} />
      <BodyCopy>
        <span>
          <h3>{heading.text}</h3>
          <EllipsisText text={text.text} length={"200"} />
          <ButtonLink to={page.url}>Read More</ButtonLink>
        </span>
      </BodyCopy>
    </StyledCarouselCard>
  )
}

export const ContentCarousel = ({ items, heading, scrollID }) => {
  return (
    <CardCarousel heading={heading} id={scrollID}>
      {items.map(item => (
        <CarouselCard {...item} />
      ))}
    </CardCarousel>
  )
}
