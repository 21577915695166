import React from "react"
import styled from "styled-components"

import { BodyCopy, ButtonLink } from "../common"

const StyledFeatureText = styled.section`
  padding: 0 ${props => props.theme.padding.hMobile};
  max-width: ${props => props.theme.containerWidths.standard};
  margin: auto;
  @media ${props => props.theme.breakpoints.medium} {
    display: grid;
    padding: 0 ${props => props.theme.padding.hDesk};
    grid-template-columns: 1fr 1fr 1fr 1fr 1fr 1fr 1fr 1fr 1fr 1fr 1fr 1fr;
    > h2 {
      grid-column: 1 / span 4;
    }
    > ${BodyCopy} {
      grid-column: 8 / span 5;
    }
    ${ButtonLink} {
      display: inline-block;
      margin-top: 1rem;
    }
  }
`

export const FeatureText = ({ heading, text, link, link_text, scrollID }) => {
  return (
    <StyledFeatureText id={scrollID}>
      <h2>{heading.text}</h2>
      <BodyCopy>
        <div dangerouslySetInnerHTML={{ __html: text.html }} />
        {link.url && <ButtonLink to={link.url}>{link_text}</ButtonLink>}
      </BodyCopy>
    </StyledFeatureText>
  )
}
