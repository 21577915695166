import React from "react"
import styled from "styled-components"

const StyledPageSpacer = styled.div`
  display: block;
  background: transparent;
  width: 100%;
  padding-bottom: ${props => props.theme.pageSpacers.small};

  padding-bottom: ${props =>
    props.size === "Standard"
      ? props.theme.pageSpacers.small
      : props.size === "Medium"
      ? props.theme.pageSpacers.medium
      : props.theme.pageSpacers.large};

  @media ${props => props.theme.breakpoints.medium} {
    padding-bottom: calc(
      ${props =>
          props.size === "Standard"
            ? props.theme.pageSpacers.small
            : props.size === "Medium"
            ? props.theme.pageSpacers.medium
            : props.theme.pageSpacers.large} + 160 * (100vw - 320px) / 2180
    );
  }
`
export const PageSpacer = ({ size }) => {
  return <StyledPageSpacer size={size} />
}
