import React from "react"
import styled from "styled-components"


import { BodyCopy, ButtonLink } from "../common"

const StyledTextBlock = styled.section`
  padding: 0 ${props => props.theme.padding.hMobile};
  max-width: ${props => props.theme.containerWidths.standard};
  margin: auto;
  h5 {
    color: ${props => props.theme.colors.pullColor};
  }
  h2 {
    max-width: ${props => props.theme.measures.narrow};
  }
  > ${ButtonLink} {
    display: inline-block;
    margin-top: 2rem;
  }
  @media ${props => props.theme.breakpoints.medium} {
    padding: 0 ${props => props.theme.padding.hDesk};
  }
`

export const TextBlock = ({ text, subheading, link, link_text, scrollID }) => {
  return (
    <StyledTextBlock id={scrollID}>
      <BodyCopy>
        {subheading.text && <h5>{subheading.text}</h5>}
        <div dangerouslySetInnerHTML={{ __html: text.html }} />
      </BodyCopy>
      {link.url && <ButtonLink to={link.url}>{link_text}</ButtonLink>}
    </StyledTextBlock>
  )
}
