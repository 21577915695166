import React from "react"
import styled from "styled-components"

import { PrismicImage, FlexBox } from "../common"

const StyledLogoGrid = styled(props => <FlexBox {...props} />)`
  padding: 0 ${props => props.theme.padding.hMobile};
  max-width: ${props => props.theme.containerWidths.standard};
  margin: 0 auto;
`

const StyledLogoCard = styled.a`
  width: calc(100% / 2);
  padding: 1.5rem;
  @media ${props => props.theme.breakpoints.medium} {
    width: calc(100% / 3);
    > div,
    > img {
      max-width: 220px;
      margin: auto;
    }
  }
`

export const LogoGrid = ({ items, scrollID }) => {
  return (
    <StyledLogoGrid wrap="wrap" id={scrollID} justifyContent="flex-start">
      {items.map(item => (
        <StyledLogoCard href={item.website.url} target="_blank">
          <PrismicImage {...item.logo} />
        </StyledLogoCard>
      ))}
    </StyledLogoGrid>
  )
}
