import React from "react"

import PropTypes from "prop-types"

import {
  PageBanner,
  PageSpacer,
  TextBlock,
  ContentCarousel,
  VideoCarousel,
  PartnersCarousel,
  PageIntro,
  ImageText,
  TwoColText,
  RegionBlock,
  FeatureText,
  LogoGrid,
  ModalForm,
  ContactForm,
} from "./"

export const SliceZone = ({ slices }) => {
  const PageNavItems = slices.filter(function(item) {
    if (item.primary) {
      return item.primary.include_in_page_nav === "true"
    }
    return null
  })
  const slice = slices.map(s => {
    switch (s.slice_type) {
      case "page_banner":
        return <PageBanner {...s.primary} key={s.id} />
      case "page_spacer":
        return <PageSpacer {...s.primary} key={s.id} />
      case "text_block":
        return <TextBlock {...s.primary} key={s.id} scrollID={s.id} />
      case "two_column_text":
        return <TwoColText {...s.primary} key={s.id} scrollID={s.id} />
      case "image___text":
        return <ImageText {...s.primary} key={s.id} scrollID={s.id} />
      case "region":
        return <RegionBlock {...s.primary} key={s.id} scrollID={s.id} />
      case "feature_text":
        return <FeatureText {...s.primary} key={s.id} scrollID={s.id} />
      case "logo_grid":
        return <LogoGrid items={s.items} key={s.id} scrollID={s.id} />
      case "modal_form":
        return <ModalForm {...s.primary} key={s.id} />
      case "contact_form":
        return <ContactForm {...s.primary} key={s.id} />
      case "page_intro":
        return (
          <PageIntro
            {...s.primary}
            key={s.id}
            scrollID={s.id}
            items={PageNavItems}
          />
        )
      case "card_carousel":
        return (
          <ContentCarousel
            {...s.primary}
            items={s.items}
            key={s.id}
            scrollID={s.id}
          />
        )
      case "video_carousel":
        return (
          <VideoCarousel
            {...s.primary}
            items={s.items}
            key={s.id}
            scrollID={s.id}
          />
        )
      case "partners_carousel":
        return (
          <PartnersCarousel
            {...s.primary}
            items={s.items}
            key={s.id}
            scrollID={s.id}
          />
        )
      default:
        return null
    }
  })
  return <div className="slice-zone">{slice}</div>
}

SliceZone.propTypes = {
  slices: PropTypes.array.isRequired,
}
