import React, { useState, useContext } from "react"
import styled from "styled-components"
import { useSpring, animated } from "react-spring"
import noScroll from "no-scroll"

import { SiteContext } from "../../context"

import { PopupForm } from "../common"

import MailIcon from "../../svg/mail.svg"

const MailButton = styled.button`
  position: fixed;
  bottom: ${props => props.theme.padding.hMobile};
  right: ${props => props.theme.padding.hMobile};
  width: 60px;
  height: 60px;
  padding: 0.9rem;
  background: ${props => props.theme.colors.pullColor};
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 100px;
  > svg {
    stroke: #fff;
    width: 100%;
  }
  @media ${props => props.theme.breakpoints.medium} {
    width: 80px;
    height: 80px;
    padding: 1.4rem;
  }
`

const AnimatedMailButton = animated(MailButton)

export const ModalForm = ({ form_name, text }) => {
  const [isOpen, setOpen] = useState(false)
  const { toggleModalOpen } = useContext(SiteContext)
  const { x } = useSpring({
    x: isOpen ? 0 : 1,
  })
  return (
    <>
      <PopupForm
        form_name={form_name}
        text={text}
        onClose={() => {
          toggleModalOpen()
          setOpen(false)
          noScroll.off()
        }}
        isOpen={isOpen}
      />

      <AnimatedMailButton
        onClick={() => {
          toggleModalOpen()
          setOpen(true)
          noScroll.on()
        }}
        style={{
          opacity: x.interpolate(x => x),
        }}
      >
        <MailIcon />
      </AnimatedMailButton>
    </>
  )
}
