import React from "react"
import styled, { css } from "styled-components"
import BackgroundImage from "gatsby-background-image"
import { BodyCopy } from "../common"

const StyledPageBanner = styled.section`
  width: 100%;
  position: relative;
  > .gatsby-image-wrapper {
    width: 100%;
    height: 300px;
    display: block;
  }

  > ${BodyCopy} {
    padding: 2rem ${props => props.theme.padding.hMobile};
    z-index: 2;
    position: relative;
    > h1 {
      max-width: ${props => props.theme.measures.standard};
      margin: 0 auto;
    }
  }
  &:before {
      content: "";
      width: 100%;
      height: 300px;
      position: absolute;
      top: 0;
      left: 0;
      background: #000;
      opacity: 0.2;
      display: block;
      z-index: 1;
    }

  ${props =>
    !props.large &&
    css`
      > ${BodyCopy} {
        position: absolute;
        height: 100%;
        display: flex;
        justify-content: flex-start;
        align-items: flex-end;
        top: 0;
        left: 0;
        color: #fff;
      }
    `}
  @media ${props => props.theme.breakpoints.medium} {
    height: ${props => (props.large ? "80vh" : "60vh")};
    max-height: 850px;
    display: flex;
    justify-content: center;
    align-items: flex-end;
    padding: 3rem 0;
    > .gatsby-image-wrapper {
      position: absolute !important;
      height: 100%;
      top: 0;
      left: 0;
    }
    > ${BodyCopy} {
      position: relative;
      height: auto;
      display: block;
      color: #ffffff;
      width: 100%;
      padding: 0 ${props => props.theme.padding.hDesk};
      max-width: ${props => props.theme.containerWidths.standard};
      font-size: 26px;
      > div {
        max-width: ${props => props.theme.measures.standard};
      }
    }
    &:before {
      height: 100%;
    }
  }
`

const StyledBannerFallback = styled.div`
  background-image: url(${props => props.src});
  background-size: cover;
  width: 100%;
  height: 300px;
  display: block;
  @media ${props => props.theme.breakpoints.medium} {
    position: absolute !important;
    height: 100%;
    top: 0;
    left: 0;
  }
`

const PageBannerImage = ({ thumbnails, localFile, url, ...props }) => {
  const sources = localFile
    ? [
        thumbnails.Mobile.localFile.childImageSharp.fluid,
        {
          ...localFile.childImageSharp.fluid,
          media: `(min-width: 768px)`,
        },
      ]
    : null
  return (
    <>
      {sources ? (
        <BackgroundImage {...props} fluid={sources} />
      ) : (
        <StyledBannerFallback
          src={url}
          {...props}
          className="styled-bg"
        ></StyledBannerFallback>
      )}
    </>
  )
}

export const PageBanner = ({ image, heading, text, size }) => {
  const isLarge = size === "Large"
  return (
    <StyledPageBanner large={isLarge}>
      <PageBannerImage {...image} />
      <BodyCopy>
        <h1>{heading.text}</h1>
        <div dangerouslySetInnerHTML={{ __html: text.html }} />
      </BodyCopy>
    </StyledPageBanner>
  )
}
