import React from "react"
import styled from "styled-components"

import { BodyCopy, PrismicImage } from "../common"

const StyledPartnersIntro = styled(props => <BodyCopy {...props} />)`
  max-width: ${props => props.theme.measures.medium};
  margin: 3rem auto;
`
const StyledPartnersCarousel = styled.div`
  --carousel-speed: 50s;
  width: 100%;
  position: relative;
  padding: 3rem 0;
  > section {
    display: flex;
    align-items: center;
    position: absolute;
    animation: var(--carousel-speed) linear 0s 1 normal none running
      linearScrollOne;
    opacity: 0;

    &:nth-child(2) {
      opacity: 1;
      animation: calc(var(--carousel-speed) * 2) linear 0s infinite normal none
        running linearScrollTwo;
    }
    &:nth-child(3) {
      animation: calc(var(--carousel-speed) * 2) linear var(--carousel-speed)
        infinite normal none running linearScrollThree;
    }
  }

  @keyframes linearScrollOne {
    0% {
      transform: translate3d(0px, 0px, 0px);
      opacity: 1;
    }
    100% {
      transform: translate3d(-100%, 0px, 0px);
      opacity: 1;
    }
  }
  @keyframes linearScrollTwo {
    0% {
      transform: translate3d(100%, 0px, 0px);
    }
    50% {
      transform: translate3d(0%, 0px, 0px);
    }
    100% {
      transform: translate3d(-100%, 0px, 0px);
    }
  }
  @keyframes linearScrollThree {
    0% {
      transform: translate3d(100%, 0px, 0px);
      opacity: 1;
    }
    50% {
      transform: translate3d(0%, 0px, 0px);
      opacity: 1;
    }
    100% {
      transform: translate3d(-100%, 0px, 0px);
      opacity: 1;
    }
  }
`
const StyledPartnerCard = styled.a`
  width: 40vw;
  flex: 0 0 auto;
  padding: 0 1rem;
  @media ${props => props.theme.breakpoints.medium} {
    width: 15vw;
  }
`

const PartnerCard = ({ logo, website }) => {
  const url = website.url || null
  return (
    <StyledPartnerCard href={url} target="_blank">
      <PrismicImage {...logo} />
    </StyledPartnerCard>
  )
}

export const PartnersCarousel = ({ heading, text, items, scrollID }) => {
  return (
    <div id={scrollID}>
      <StyledPartnersIntro center={true}>
        <h2>{heading.text}</h2>
        <div dangerouslySetInnerHTML={{ __html: text.html }} />
      </StyledPartnersIntro>
      <StyledPartnersCarousel>
        <section>
          {items.map(item => (
            <PartnerCard {...item} />
          ))}
        </section>
        <section>
          {items.map(item => (
            <PartnerCard {...item} />
          ))}
        </section>
        <section>
          {items.map(item => (
            <PartnerCard {...item} />
          ))}
        </section>
      </StyledPartnersCarousel>
    </div>
  )
}
