import React, { useState, useContext } from "react"
import styled from "styled-components"
import ReactPlayer from "react-player"
import { useTransition, animated } from "react-spring"

import { SiteContext } from "../../context"

import PlayIcon from "../../svg/play-icon.svg"
import CloseIcon from "../../svg/close.svg"

import { PrismicImage, CardCarousel, FlexBox } from "../common"

const StyledVideoCard = styled.div`
  flex: 0 0 auto;
  width: 100%;
  user-select: none;
  padding: 0 ${props => props.theme.padding.hMobile};
  box-sizing: content-box;
  position: relative;
  > ${FlexBox} {
    position: absolute;
    background: rgba(0, 0, 0, 0.4);
    height: 100%;
    width: calc(100% - 3rem);
    left: 1.5rem;
    bottom: 0;
    color: #fff;
    align-content: flex-end;
    padding: 1rem 2rem;
    > svg {
      width: 1.2rem;
    }
    > h4 {
      width: 100%;
      margin-top: 0.2rem;
    }
  }
  @media ${props => props.theme.breakpoints.medium} {
    > ${FlexBox} {
      > h4 {
        width: auto;
        margin-top: 0;
      }
    }
  }

  @media ${props => props.theme.breakpoints.large} {
    width: 760px;
    padding: 0 3rem 0 0;
    > ${FlexBox} {
      left: 0;
      padding: 3rem;
    }
    &:last-child {
      padding: 0;
      > ${FlexBox} {
        width: 100%;
      }
    }
  }
`

export const StyledVideoModal = styled.div`
  width: 100vw;
  height: 100vh;
  top: 0px;
  left: 0px;
  position: fixed;
  z-index: 99999;
  background: rgba(0, 0, 0, 0.8);
  padding: 4rem;
  display: flex;
  align-items: center;
  justify-content: center;
  video:focus {
    outline: none;
  }
`
const StyledCloseButton = styled.button`
  position: absolute;
  right: 2rem;
  top: 2rem;
  > svg {
    width: 1.8rem;
    fill: ${props => props.theme.colors.pullColor};
  }
`
const AnimatedVideoModal = animated(StyledVideoModal)

const VideoCard = ({ video_poster, video, video_title, ...props }) => {
  return (
    <StyledVideoCard {...props}>
      <PrismicImage {...video_poster} />
      <FlexBox alignItems="center">
        <PlayIcon />
        <h4>{video_title.text}</h4>
      </FlexBox>
    </StyledVideoCard>
  )
}

export const VideoModal = ({ video, onClose }) => {
  const [playing, setPlaying] = useState(false)

  const transitions = useTransition(video, null, {
    from: { opacity: 0 },
    enter: { opacity: 1 },
    leave: { opacity: 0 },
  })

  return transitions.map(
    ({ item, key, props }) =>
      item && (
        <AnimatedVideoModal key={key} style={props}>
          <StyledCloseButton onClick={() => onClose()}>
            <CloseIcon />
          </StyledCloseButton>
          {video && (
            <ReactPlayer
              url={video.url}
              playing={playing}
              playsinline={false}
              onReady={() => setPlaying(true)}
              onEnded={() => onClose()}
              controls={true}
            />
          )}
        </AnimatedVideoModal>
      )
  )
}

export const VideoCarousel = ({ heading, items, scrollID }) => {
  const { toggleModalOpen } = useContext(SiteContext)
  const [video, setVideo] = useState(null)
  return (
    <>
      <CardCarousel heading={heading} id={scrollID}>
        {items.map(item => (
          <VideoCard
            {...item}
            onClick={() => {
              toggleModalOpen()
              setVideo(item.video)
            }}
          />
        ))}
      </CardCarousel>
      <VideoModal
        video={video}
        onClose={() => {
          toggleModalOpen()
          setVideo(null)
        }}
      />
    </>
  )
}
